import React, { Component } from 'react';
import { Link } from 'gatsby';
import './styles/styles.scss';
import Image from '../../../../components/image/Image';
import { scrollToSection, getPathname } from '../../../../helpers/globals';
import { slide as Menu } from 'react-burger-menu';

class Navigation extends Component {

    componentDidMount() {
    }

    render() {
        const isSubPage = getPathname()?.includes('/kontakt') || getPathname()?.includes('/rodo');
        const { white } = this.props;

        return (
            <>
                <nav className={white ? 'navigation navigation-white' : 'navigation'}>
                    <a href='/'>
                        <Image src={white ? 'logo-white.svg' : 'logo.svg'}
                            className='mx-auto shadow-xl navigation-logo'
                            alt='logo' />
                    </a>
                    {!isSubPage &&
                        <div className='navigation--links'>
                            <Link to='#activity' onClick={() => scrollToSection('#activity')}>O nas</Link>
                            <Link to='#pill' onClick={() => scrollToSection('#pill')}>DBD w pigułce</Link>
                            <Link to='#operations' onClick={() => scrollToSection('#operations')}>Obszar działania</Link>
                            <Link to='#cooperations' onClick={() => scrollToSection('#cooperations')}>Korzyści</Link>
                            <Link to='#caseStudies' onClick={() => scrollToSection('#caseStudies')}>Case studies</Link>
                            <Link className='button' to='/kontakt'>Kontakt</Link>
                            <Link className='button-language' to='https://digitalbuildingdata.com/'>
                                <Image src="world.svg" />
                                <span>
                                    EN
                                </span>
                            </Link>
                        </div>
                    }
                </nav>
                <div className='hamburger'>
                    <Menu right width={'280px'}>
                        <Link to='#activity' onClick={() => scrollToSection('#activity')}>O nas</Link>
                        <Link to='#pill' onClick={() => scrollToSection('#pill')}>DBD w pigułce</Link>
                        <Link to='#operations' onClick={() => scrollToSection('#operations')}>Obszar działania</Link>
                        <Link to='#cooperations' onClick={() => scrollToSection('#cooperations')}>Korzyści</Link>
                        <Link to='#caseStudies' onClick={() => scrollToSection('#caseStudies')}>Case Studies</Link>
                        <Link className='button' to='/kontakt'>Kontakt</Link>
                    </Menu>
                </div>
            </>
        )
    }

}

export default Navigation;